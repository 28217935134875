.rabbit-ai-title {
    max-width: 600px;
}
.rabbit-ai-pic {
    width: 100%;
}
.rabbit-ai-header-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 20px 20px;
    max-width: 1400px;
    flex-direction: column;
}
.rabbit-ai-article-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 15px 50px;
}
.rabbit-ai-article{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chrome-extension-header-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 20px;
    /* max-width: 1400px; */
    flex-direction: column;
}
.chrome-extension-article-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.video-container {
    margin: 20px 0;
    border-radius: 20px;
    border: 10px solid #70707015;
    overflow: hidden;
    width: fit-content;
    filter: drop-shadow(0 0 0.5rem var(--ynt-lightblue));
}
.slider-container{
    width: 80vw;
    box-sizing: border-box;
    overflow: hidden;
}
.swiper-slide{
    display: flex !important;
    align-items: center;
    justify-content: center;
}

  
.swiper-slide .thumb-swiper img{
    width: 180px;
    margin:5px 10px;
    opacity: 0.6;
    border-radius: 10px;
}
  
.swiper-slide .thumb-active img{
    opacity: 1;
    border: 3px solid #705DFE;
}

@media only screen and (max-width: 599px) {
    .swiper-slide .thumb-swiper img{
        width: 100px;
    }
    .rabbit-ai-header-container {
        padding: 100px 20px 0px;
    }
  }