.ai-bot-background-cover{
    background-image: url('/public/images/products/aibot/header-background.png');
    background-position: bottom;
    background-size: cover;
}
.ai-bot-video-background-cover{
    background-image: url('/public/images/products/aibot/video-background.png');
    background-position: "bottom";
    background-size: "cover";
}
.products-column-background-cover{
    background-image: url('/public/images/products/header-pic.png');
    background-position: bottom;
    background-size: cover;
    overflow:hidden;
}
.video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
    cursor: pointer; 
  }
  
.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 600px) and (max-width: 899px) {
    .ai-bot-background-cover {
        background-image: url('/public/images/products/aibot/header-background@2x.png');
    }
    .ai-bot-video-background-cover{
        background-image: url('/public/images/products/aibot/video-background@2x.png');
    }
    .products-column-background-cover{
        background-image: url('/public/images/products/header-pic@2x.png');
    }
}


@media (min-width: 900px) and (max-width: 1399px) {
    .ai-bot-background-cover {
        background-image: url('/public/images/products/aibot/header-background@3x.png');
    }
    .ai-bot-video-background-cover{
        background-image: url('/public/images/products/aibot/video-background@3x.png');
    }
    .products-column-background-cover{
        background-image: url('/public/images/products/header-pic@3x.png');
    }
}


@media (min-width: 1400px) and (max-width: 1799px) {
    .ai-bot-background-cover {
        background-image: url('/public/images/products/aibot/header-background@4x.png');
    }
    .ai-bot-video-background-cover{
        background-image: url('/public/images/products/aibot/video-background@4x.png');
    }
    .products-column-background-cover{
        background-image: url('/public/images/products/header-pic@4x.png');
    }
}


@media (min-width: 1800px) {
    .ai-bot-background-cover {
        background-image: url('/public/images/products/aibot/header-background@5x.png');
    }
    .ai-bot-video-background-cover{
        background-image: url('/public/images/products/aibot/video-background@5x.png');
    }
    .products-column-background-cover{
        background-image: url('/public/images/products/header-pic@5x.png');
    }
}

.ai-header-title h1{
    font-size:45px;
    text-align:center;
}
.monetize-ai-header-title h1{
    font-size:45px;
}
.monetize-ai-header-title p{
    background-color: rgba(255,255,255,0.5);
}
@media (max-width: 899px) {
    .monetize-ai-header-title h1 ,.monetize-ai-header-title p{
        text-align:center;
    }
}
.ai-header-subtitle p{
    padding: 5px 0;
    text-align:center;
}

.ai-header-subtitle span{
    color:#3E78E6;
    font-weight: 500;
}
