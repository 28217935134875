.usescase-header-container {
    display: flex;
    justify-content: center;
    min-height: 60vh;
}

.header-title {
    padding: 50px 0;
    display: flex;
    flex-direction: column !important;
    text-align: center;
}

.header-title h1 {
    padding: 20px 0;
    font-size: 60px;
}

.header-title p {
    padding: 5px 0;
}

.usecases-article-container {
    padding: 30px 10px;
}

.video-container {
    margin: 20px 0;
    border-radius: 20px;
    border: 10px solid #70707015;
    overflow: hidden;
    filter: drop-shadow(0 0 0.5rem var(--ynt-lightblue));
}

@media only screen and (min-width: 599px) and (max-width: 899px) {
    .header-title h1 {
        font-size: 45px;
    }
}

@media only screen and (max-width: 599px) {
    .header-title h1 {
        font-size: 35px;
    }
}

.usecases-background-cover {
    background-image: url('/public/images/usecases/background.png');
    background-position: center;
    background-size: cover;
    overflow: hidden;
}

@media (min-width: 600px) and (max-width: 899px) {
    .usecases-background-cover {
        background-image: url('/public/images/usecases/background@2x.png');
    }
}


@media (min-width: 900px) and (max-width: 1399px) {
    .usecases-background-cover {
        background-image: url('/public/images/usecases/background@3x.png');
    }
}


@media (min-width: 1400px) and (max-width: 1799px) {
    .usecases-background-cover {
        background-image: url('/public/images/usecases/background@4x.png');
    }
}


@media (min-width: 1800px) {
    .usecases-background-cover {
        background-image: url('/public/images/usecases/background@5x.png');
    }
}