/*root styles start*/
:root{
    /*color styles*/
    --ynt-primary:#3976EF;
    --ynt-secondary:#40B2F2;
    --ynt-lightpurple:#EEDCFD;
    --ynt-purple:#705DFE;
    --ynt-lightgreen:#BCE3FC;
    --ynt-lightblue:#B9CEFC;
    --ynt-lightgrey:#F8F8F8;
    --ynt-mildgrey:#e0e0e0;
    --ynt-midgrey:#707070;
    --ynt-darkgrey:#424242;
    --ynt-transparent:rgba(0,0,0,0);
    /*font size*/
    --ynt-font-s:12px;
    --ynt-font-normal:14px;
    --ynt-font-m:16px;
    --ynt-font-l:20px;
    --ynt-font-xl:24px;
    --ynt-font-xxl:28px;
    --ynt-font-xxxl:38px;
    --ynt-font-title:48px;
    /*font weight*/
    --ynt-font-bold:600;
    --ynt-font-exbold:800;
    /*letter spacing*/
    --ynt-font-space-l:-0.24px;
    --ynt-font-space-m:-0.06px;
    --ynt-font-space-s:-0.05px;
    /*page max-width*/
    --ynt-max-width: 1200px;
}
/*root styles end*/

/*Glogal styles start*/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    margin: 0;
    font-family: system-ui,-apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--ynt-midgrey);
    font-weight: 300;
    background-color: #fff;
  }
  h1,h2,h3{
    color:black;
  }
  a {
    text-decoration: none;
    color:var(--ynt-darkgrey);
  }
  html {
    height: 100%;
  }

  #younet-embed-bubble {
      z-index: 2147483647 !important;
  }
  
  /*Glogal styles end*/

  /*typography start*/
h1 {
    font-weight: var(--ynt-font-bold);
    font-size:var(--ynt-font-xxxl);
    letter-spacing: var(--ynt-font-space-l)
}
h2 {
    font-weight: var(--ynt-font-bold);
    font-size:var(--ynt-font-xxl);
    letter-spacing: var(--ynt-font-space-l)
}
h3 {
    font-weight: var(--ynt-font-bold);
    font-size:var(--ynt-font-xl);
    letter-spacing: var(--ynt-font-space-m)
}
h4 {
    font-weight: var(--ynt-font-bold);
    font-size:var(--ynt-font-l);
    letter-spacing:var(--ynt-font-space-m)
}
h5 {
    font-weight: var(--ynt-font-bold);
    font-size:var(--ynt-font-m);
    letter-spacing: var(--ynt-font-space-s)
} 
h6 {
    font-weight: var(--ynt-font-bold);
    font-size:var(--ynt-font-normal);
    letter-spacing: var(--ynt-font-space-s)
}
p {
    font-size:var(--ynt-font-normal);
}
.ynt-text-small {
    font-size:var(--ynt-font-s);
    letter-spacing: var(--ynt-font-space-s)
}
/*typography end*/

/*others start*/
.column-container{
  padding:0 15px;
}
.contentPic{
  width:90%;
  padding:40px 0px;
}
.observer{
  position: absolute;
  top:50%;
}
.long-observer{
  position: absolute;
  top:20%;
}
.basic-header-container{
  position: relative;
  display: flex;
  align-items: center;
  padding: 120px 30px 50px;
}
.basic-header-container img{
  width: 100%;
  max-width: 900px;
  margin-right: 0;
  margin-left: auto;
  margin-right: auto;
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
.typing-cursor {
  display: inline-block;
  vertical-align:middle;
  background-color: var(--ynt-primary);
  width: 6px; 
  height: 1.5em; 
  animation: blink 1s infinite;
}

@keyframes blink {
  from, to { opacity: 0.7}
  50% { opacity: 0 }
}
.typing-effect {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 1s steps(50, end), blink-caret 0.75s step-end infinite;
}

@media only screen and (min-width: 599px) and (max-width: 899px) {
  .mobile-reverse{
    flex-direction: column-reverse !important;
  }
  .basic-header-container{
    padding: 100px 30px; } 
}

@media only screen and (max-width: 599px) {
  .mobile-reverse{
    flex-direction: column-reverse !important;
  }
  .basic-header-container{
    height: auto;
    padding: 80px 30px; } 
  .contentPic {
    padding: 0 0 10px;
  }
}

/*otherss end*/

/*align sets*/
.center-align{
    position: relative;
    display:flex;
    justify-content: center;
    align-items:center;
}
.left-align{
  margin-left: 0;
  margin-right: auto;
}
.right-align{
  margin-left: auto;
  margin-right: 0;
}
/*align sets end*/

/*title component*/
.title-container{
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  width: 100%;
}
.title-container h1{
  color:black;
  text-align:center;
  padding:50px 0 20px 0;
  width: 60%;
}
@media only screen and (max-width: 599px) {
  .title-container h1{
    width: 100%;
  }
}
/*info page starts*/
.info-container{
  position: relative;
  padding: 100px 40px;
}
/*info page ends*/

/*support logo starts*/
.supported-logos{
  width: 100%;
  padding: 10px 20px;
}

.supported-logos p{
  font-size: medium;
  padding: 5px 0;
}

.supported-logos img{
  max-width: 300px;
  max-height: 35px;
}
@media only screen and (max-width: 599px) {
    .supported-logos {
      padding:0 15px;
    }
    .supported-logos p{
        width: 100%;
        text-align: center;      
    }
    .supported-logos img{
        max-width: 150px;
        max-height: 18px;
    }
}
/*support logo ends*/

/*footer starts*/
.ynt-footer-container {
  overflow: hidden; 
  flex-direction: row;
  border-top: 1px solid var(--ynt-mildgrey);
}
.footer-content{
  padding: 10px 0 10px 70px;
}
.copyright {
color: var(--ynt-midgrey);
padding: 0 40px;
}
.footer-social-inline,.footer-links-inline {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
  .footer-social-inline a {
    margin:5px 5px;
    color: var(--ynt-midgrey); }
    .footer-social-inline a i {
      line-height: 1;
      display: block; }
    .footer-social-inline a:hover {
      color: var(--ynt-primary); }
      
      
      .footer-links-inline a {
        margin:5px 15px;
        color: var(--ynt-midgrey); }
        .footer-links-inline a i {
          line-height: 1;
          display: block; }
          .footer-links-inline a:hover {
          color: var(--ynt-primary); }

@media only screen and (max-width: 899px) {
  .ynt-footer-container {
    padding: 10px 0px; } 
  .copyright{
    text-align: center;
    padding: 10px;
  }
}
/*footer ends*/

/* cookie consent starts*/
.policy-container{
  background-color: rgba(20,20,20,.8);
  color:white;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position:sticky;
  bottom:0;
  z-index: 999;
}
.policy-container a{
  color: white;
}
.policy-container a:hover{
  color: var(--pxft-primary);
}
/* cookie consent ends*/


/* Hide scrollbar for Chrome, Safari, and Opera */
.quotes-slider::-webkit-scrollbar {
  width: 0.5em;
}

.quotes-slider::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Hide scrollbar for Firefox */
.quotes-slider {
  scrollbar-width: thin;
}

.quotes-slider::-moz-scrollbar-thumb {
  background-color: transparent;
}
