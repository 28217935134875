
.company-aboutus-header{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 30px 20px;
    text-align: center;
    flex-direction: column;
}
.company-aboutus-header p{
    padding: 5px 0;
}
.company-aboutus-header h1{
    margin: 30px 0;
}
.company-aboutus-header .header-content,.company-aboutus-container .header-content{
    position: relative;
    max-width: 1000px;
    padding:30px;
    background-color: rgba(255, 255, 255, 0.67);
}

.company-aboutus-header .quotation img{
    width:50px;
    position:absolute;
    top:-20px;
    left:40px;
}
.company-aboutus-container .quotation img{
    width:50px;
    position:absolute;
    top:-20px;
    right:40px;
}
.company-aboutus-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 50px;
  }
  .company-aboutus-content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 30px;
    max-width: 1200px;
  }
  .company-aboutus-content h1{
    padding: 10px 0;
  }
  .company-aboutus-content p{
    padding: 10px 0;
    white-space: pre-line;
  }

.company-aboutus-container  .divider-column {
    flex-direction:column;
    background-position: center;
    color:white;
    padding:75px 30px;
    margin: 20px 0 50px;
}

.company-aboutus-container .join-us-container .content {
    padding: 5px 0;
    font-size: 18px;
}
.company-aboutus-container .join-us-container .content span{
    color:var(--ynt-primary);
    font-weight: 500;
}
.career-article-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 50px;
}
.career-feature-column {
    flex-direction: column;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.career-join-column {
    flex-direction: column;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 10px 0 50px;
}
.career-join-column h4 {
    padding: 5px 0;
    color: var(--ynt-purple);
    text-align: center;
}
.career-join-column p,.career-join-column a {
    padding: 10px 0;
    text-align: center;
}