/*updated home starts*/
.home-navi {
    display: flex;
    padding: 0px 30px;
}

.home-navi a {
    padding: 0 20px;
}

.home-article {
    padding: 0px 40px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    flex-direction: row;
    min-height: calc(100vh - 134px);
}

.home-article .logoImg {
    width: 300px;
}

.home-article .title {
    min-height: 170px;
    padding: 0 10px;
    font-weight: 400;
}

.home-article-container .particles {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

/*header starts*/
.home-header-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 760px;
    overflow: hidden;
}

.home-title-container {
    position: relative;
    height: 50vh;
    display: flex;
    width: 40%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-title-container .home-title {
    position: absolute;
    width: 160%;
    height: 70%;
    display: flex;
    flex-direction: column;
}

.home-title .words-container {
    padding: 140px 0 0 0;
}

.home-title .title {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 0 0 10px 0;
}

.home-title .subtitle {
    color: var(--ynt-midgrey);
    width: 55%;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    line-height: 1.5;
    letter-spacing: 0.8px;
    font-size: 14px;
    font-weight: 300;
}

.home-title h1 {
    font-size: 45px;
    font-weight: var(--ynt-font-exbold);
    text-align: center;
    margin: auto;
    font-family: Satoshi, sans-serif;
    letter-spacing: 0.1px;
}

.home-title .btn-container {
    display: flex;
    justify-content: center;
    z-index: 1;
}

.home-title .btn-container button {
    width: 180px;
    height: 50px;
}

.home-title .btn-container p {
    margin: 0;
}

.home-header-pic {
    position: absolute;
    top: -400px;
    padding: 10px 30px;
}

.home-header-pic img {
    width: 100%;
    border-radius: 30px;
}

.overview-title {
    margin: 30px 0 20px 0;
    max-width: 600px;
    padding: 0 30px;
}

.overview-title h1 {
    /* font-weight: 300; */
}

.overview-title p {
    padding: 5px 0;
}

.overview-title .credit-card {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.overview-title .credit-card p {
    font-size: 12px;
    padding: 0 5px;
}

.overview-header-pic {
    margin: 30px 0 20px 0;
    border-radius: 20px;
    border: 10px solid #70707015;
    overflow: hidden;
    filter: drop-shadow(0 0 2.5rem var(--ynt-lightblue));
}

.overview-header-pic img {
    width: 100%;
}

.auth-cta {
    width: 100%;
}

.rolling-icons-container {
    padding: 10px 0;
    overflow: hidden;
}

.rolling-icons {
    width: 100%;
    position: relative;
}

.type-animation-span {
    font-size: 1.8rem;
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-in-out, transform 1s ease-in-out;
}
.info-span {
    background-color: var(--ynt-lightgrey);
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
    font-weight: 400;
    /* transition:opacity 1s ease-in; */
}
.sticky-link-container {
    position: fixed;
    top:0;
    z-index: 99;
    transition: 500ms;
    animation: slideDown .95s ease forwards;
}

@keyframes slideDown {
    0% {
    transform: translateY(-100%);
}                                                                               
    100% {
        transform: translateY(0);
    }
}
.sticky-link {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sticky-link a{
    color:white;
    font-weight: 500;
    background-color: var(--ynt-primary);
    filter: drop-shadow(0px 1px 5px rgba(0,0,0,0.12));
    padding:15px 45px;
    margin-top: 10px;
    border-radius: 20px;
}
  
  /* Define the animation keyframes */
  @keyframes goDownAndDisappear {
    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  @keyframes goLeftAndDisappear {
    to {
        opacity: 0;
        transform: translateY(0) translateX(-100%);
      }
  }
  
  
  /* Apply the animation when the element has a specific class */
  .type-animation-span.go-down-and-disappear {
    animation: goDownAndDisappear 1s forwards;
  }
  .type-animation-span.go-left-and-disappear {
    animation: goLeftAndDisappear 1s forwards;
  }


@media only screen and (min-width: 599px) and (max-width: 899px) {
    .home-navi {
        padding: 10px 30px;
    }
    .home-article .title {
        min-height: 100px !important;
        max-width: 450px;
    }

    .home-title .words-container {
        padding: 100px 0;
    }

    .home-title .title {
        width: 70%;
    }

    .home-title h1 {
        font-size: var(--ynt-font-xxxl);
    }

    .home-article-container {
        padding: 50px 0 0 0 !important;
    }

    .overview-title {
        margin: 50px 0 20px 0;
    }

    .home-header-pic {
        top: -360px;
    }
}

@media only screen and (max-width: 599px) {
    .home-navi {
        padding: 10px 30px;
    }
    .home-article .title {
        min-height: 140px;
        max-width: 450px;
    }

    .home-header-container {
        height: 100vh;
    }

    .home-title .words-container {
        padding: 120px 0;
    }

    .home-title h1 {
        font-size: var(--ynt-font-xxl);
    }

    .home-title .subtitle {
        font-size: var(--ynt-font-m);
    }

    .home-article-container {
        padding: 10px 0 0 0 !important;
    }

    .home-header-pic {
        top: -260px;
    }

    .home-article {
        padding: 20px 20px 50px;
    }

    .home-article .logoImg {
        width: 100%;
    }

    .overview-title {
        margin: 40px 0 20px 0;
    }
    .overview-title h1 ,.overview-title p{
        text-align:center;
    }
    .overview-header-pic {
        margin: 20px 0;
    }
}

/*header ends*/

.home-feature-column {
    flex-direction: column;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.home-feature-column img {
    width: 100%;
    max-width: 600px;
}

.home-quotation-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.home-steps-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.home-steps-column p {
    white-space: pre-line
}

.home-steps-column .title {
    color: black;
}

.home-steps-column img {
    width: 100%;
    max-width: 600px;
}

.steps-content {
    height: 100%;
}

.steps-content-column {
    position: relative;
    height: 100%;
    display: flex;
    padding: 0px 30px
}

.home-steps-column .step-marker {
    position: relative;
    width: 40px;
    height: 100%;
}

.home-steps-column .step-marker .step-block-block {
    background-color: white;
    width: 100%;
}

.home-steps-column .step-marker .step-line {
    display: flex;
    flex-direction: column;
}

.home-steps-column .step-marker .step-marker-line {
    height: 100%;
    background-color: #E7EBFF;
    width: 6px
}

.home-steps-column .step-marker .step-line-top img {
    position: absolute;
    top: 0;
}

.home-steps-column .step-marker .step-line img {
    position: absolute;
    top: 45%;
}

.home-faq-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 0 0 80px 0;
}

.home-faq-column h4 {
    padding: 10px 15px;
}

.home-faq-column p {
    padding: 0 15px 10px 15px;
}

.home-logo {
    display: flex;
    justify-content: flex-start
}

@media only screen and (max-width: 1000px) {
    .home-article {
        flex-direction: column;
    }

    .home-logo {
        justify-content: center
    }

}

@media only screen and (max-width: 350px) {
    .home-article .title {
        min-height: 206px !important;
    }
}