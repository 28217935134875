.blog-header-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 50px 50px 0;
}

.blog-header-container .title {
  color: black;
  padding: 20px 0;
}

.blog-header-container .divider {
  border-top: 1px solid var(--ynt-midgrey);
  width: 100%;
  padding: 10px 0;
}

.blog-header-container .time {
  padding: 10px 0;
  color: var(--ynt-midgrey);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blog-header-container .time .category {
  color: var(--ynt-midgrey);
  padding: 0 10px;
}

.blog-header-container .sharebtn-container {
  display: flex;
  justify-content: flex-end;
}

.blog-content {
  color: var(--ynt-darkgrey);
  padding: 10px 50px;
}

.blog-content a {
  cursor: pointer;
}

.blog-content .content {
  width: 100%
}

.blog-content .row {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.blog-content .full-width {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.blog-content .text-column {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
}

.blog-content .pic-colomn {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-content .text-column-60 {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.blog-content .pic-column-40 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.blog-content a {
  font-weight: 500;
}

.blog-content h5 {
  padding: 10px 0;
}

.blog-content .content p {
  white-space: pre-wrap;
  /* line-height: 1.5; */
}

.blog-content .content ul {
  padding: 20px;
}

.blog-content .content ul li {
  padding: 5px;
}

.blog-content .content img {
  width: 300px;
  padding: 0 20px;
}

.blog-content .content .italic {
  font-style: italic;
}

.blog-content .content .big-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-content .content .big-img img {
  width: 600px;
}

.blog-content .content .full-img {
  width: 100%;
  padding: 0;
}

.blog-content .content .border-img {
  border: 1px solid #00000066;
}

@media only screen and (max-width: 599px) {
  .blog-content b {
    text-align: center;
  }

  .blog-header-container {
    margin: 30px 0 20px 0;
  }

  .blog-content .row {
    flex-direction: column;
  }

  .blog-content .mobile-reverse {
    flex-direction: column-reverse !important;
  }

  .blog-content .full-width {
    /* flex-direction: column-reverse; */
    align-items: center;
    justify-content: center;
  }

  .blog-content .reverse {
    flex-direction: column !important;
  }

  .blog-content .text-column {
    width: 100%;
  }

  .blog-content .pic-column {
    width: 100%;
  }

  .blog-content .text-column-60 {
    width: 100%;
  }

  .blog-content .pic-column-40 {
    width: 100%;
  }

  .blog-content .content img {
    width: 90%;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blog-content .content .big-img img {
    width: 100%;
  }
}

.blog-content .content .right-img {
  float: right;
}

.blog-content .content .left-img {
  float: left;
}

.blog-content .content .center-img {
  padding: 40px
}


@media only screen and (max-width: 599px) {
  .blog-content .content img {
    width: 90%;
    padding: 40px 20px;
  }
}

.blog-content .btn-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 30px 0;
}