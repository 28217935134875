/*terms&conditions starts*/
.terms-and-conditions-container{
    position: relative;
    padding:60px 0 0px;
    word-break: break-all;
}
.terms-and-conditions-container a{
    font-weight: 600;
    color: var(--ynt-midgrey);
}
.terms-and-conditions-container p{
    padding: 5px 0;
}
.terms-and-conditions-container h3{
    padding: 10px 0;
}
.terms-and-conditions-container li{
    padding: 5px 0;
    font-size: var(--ynt-font-normal);
}

.tabcontent-container .tab{
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid var(--ynt-mildgrey);
    padding: 24px 0 0 0;
}
.tabcontent-container .tab-mobile{
    display: none;
}
@media only screen and (max-width: 899px) {
    .tabcontent-container .tab{
        display: none;
    }
    .tabcontent-container .tab-mobile{
        display: block;
    }
}

.terms-and-conditions-container .tablinks{
    font-size: 1.35em;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
    color: var(--mid-gray);
    display: inline-block;
    padding: 0 10px 14px;
    border-bottom: 3px solid transparent;
    transition: ease-in ease-out 3s;
}

.terms-and-conditions-container .tablinks a{
    text-decoration: none;
}
.terms-and-conditions-container .tablinks:hover {
    color: var(--ynt-primary);
}

.terms-and-conditions-container .policy-title{
    color:var(--ynt-primary);
    padding:10px 0;
    font-weight: 600;
}

.terms-and-conditions-container .tab-active {
color: var(--ynt-primary);
border-color:var(--ynt-primary);
}

.terms-and-conditions-container .tabcontent{
    width:100%;
    justify-content: center;
    display: none;
}

.terms-and-conditions-container .tabcontent ul{
    padding: 0 0 0 30px;
}

.terms-and-conditions-container .tabcontent span::before{
    content: "\a\00a0\00a0\00a0\00a0";
    white-space: pre;
}
.terms-and-conditions-container .updated-time{
    color:var(--ynt-midgrey);
    font-size: 13px;
    padding:20px 0;
}

/*terms&conditions ends*/

.brandbook-container{
    position: relative;
    padding:100px 15px;
}
/*brandbook starts*/
.brandbook-container .brandbook-content{
    padding: 30px 0;
  }
  .brandbook-container .color-box{
    width: 40%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    font-weight: 500;
    margin:20px;
  }
  .fine-print-container{
    padding:20px 10px 60px;
    width: 90%;
  }
  .fine-print-container .content{
    width: 90%;
  }
  .fine-print-container h3{
    padding: 10px;
    margin: 20px;
    width: 180px;
    text-align: center;
  }
  .fine-print-container p{
    padding: 5px 15px 5px;
  }
  /*brandbook ends*/