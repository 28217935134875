.nav {
    position:absolute;
    top:0;
    background-color: rgba(255,255,255,0);
    width: 100%;
    height: 80px;
    z-index: 50;
}
.nav-navi-contents{
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    padding: 0 40px;
}
.logoImg{
    width: 180px;
    padding: 10px 0 0 0;
}
.navibtn :hover{
    color:var(--ynt-primary);
}

.title-wrapper {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.site-main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: var(--ynt-font-normal);
  }
    
    .site-main-menu > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0;
      padding-left: 0;
      list-style: none; }

      .site-main-menu > ul > li {
        position: relative; 
    }
        .site-main-menu > ul > li > a {
          line-height: 1.375;
          display: block;
          padding: 28px 15px;
          color: var(--ynt-darkgray); 
          border-bottom: 2px solid var(--ynt-transparent); 
        }

        .site-main-menu-light > ul > li > a {
            color: #fff !important; 
          }

        .site-main-menu > ul > li > a .menu-text {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center; 
        }

        .site-main-menu > ul > li:hover > a {
          color: var(--ynt-primary);
          border-bottom: 2px solid var(--ynt-primary); 
        }
        .site-main-menu-light > ul > li:hover > a {
          color: var(--ynt-lightgrey) !important; 
        }
        .site-main-menu > ul > li:hover > .sub-menu {
          visibility: visible;
          margin-top: 10px;
          opacity: 1; }

    .site-main-menu .sub-menu {
      position: absolute;
      z-index: 50;
      top: 100%;
      right: 0;
      visibility: hidden;
      width: 240px;
      margin-top: 20px;
      margin-bottom: 0;
      padding: 0px 0;
      list-style: none;
      -webkit-transition: all 0.4s ease 0.2s;
      -o-transition: all 0.4s ease 0.2s;
      transition: all 0.4s ease 0.2s;
      opacity: 0;
      border-radius: 5px;
      background-color: #fff;
      -webkit-box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
      box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05); }

      .site-main-menu .sub-menu::before {
        content: "";
        position: absolute;
        top: -10px;
        right: 40px;
        text-align: center;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent #fff transparent; }

      .site-main-menu .sub-menu li {
        position: relative; }
        .site-main-menu .sub-menu li a{
          line-height: 1.375;
          display: block;
          padding: 16px 30px;
          white-space: nowrap;
          color: var(--ynt-darkgray);
         }

        .site-main-menu .sub-menu li a .menu-text {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center; }

        .site-main-menu .sub-menu li:hover > a, .site-main-menu .sub-menu li.active > a{
            background-color: var(--ynt-lightgrey);
            border-radius: 5px;
            color:var(--ynt-primary);
        }
        .site-main-menu .sub-menu li:hover > .sub-menu {
          visibility: visible;
          margin-top: 0px;
          opacity: 1; }

        .site-main-menu .sub-menu .sub-menu {
        top: -18px;
        left: 100%; }
        .site-main-menu .sub-menu .sub-menu::before {
          display: none; }


/*mobile menu*/

.nav-mobile-menu {
  overflow-y: auto;
  box-sizing: border-box;
}

.nav-mobile-menu .settingIcon{
  border: 1px solid var(--ynt-midgrey);
  border-radius: 3px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 899px) {
  .nav-navi-contents{
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .nav-navi-contents{
    padding: 0 20px;
  }

  .title-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .site-main-menu > ul > li > a {
    padding: 28px 5px;
  }
}
