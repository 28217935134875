/*header starts*/
.business-header-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    overflow: hidden;
}
.business-header-container img{
    border-radius: 15px;
}
.business-article-container{
    padding: 0 15px 50px;
}
.business-feature-column{
    flex-direction: column;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.business-feature-column-des{
    flex-direction: column;
    width: 100%;
    padding: 0 0 50px;
}
.business-feature-column-des h1{
    padding: 20px 0;
    margin: 30px 0;
    box-sizing: border-box;
}
.business-feature-column-des p{
    padding: 5px 0;
}
.business-feature-column-des img{
    width: 100%;
    height: auto;
}
@media only screen and (max-width: 599px) {
    .business-feature-column-des{
        padding: 0;
    }
}


/*pricing page starts*/
.prescription-btns button{
    color: var(--ynt-darkgrey);
    justify-content: center;
}
.prescription-btns button:hover{
    color: black;
    background-color: var(--ynt-lightgrey);
}

.prescription-btns .selected{
    color: black;
    background-color: white;
}
.annual-badge {
    color: white;
    background: rgb(133,124,245);
    background: linear-gradient(227deg, rgba(133,124,245,1) 0%, rgba(42,123,244,1) 100%);
    padding: 0px 8px;
    margin: 0 5px;
    border-radius: 5px;
    font-size: 12px;
}
.delete-price {
    text-decoration: line-through;
    color:var(--ynt-primary);
    font-size: 23px;
    font-weight: 300;
}

.price-table-container{
    padding: 20px 0;
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;
}
.price-table-header{
    color:var(--ynt-darkgrey)
}
.price-table-header h5{
    padding:10px 0;
}
.price-table-cell {
    min-width: 100px;
}
.price-table-credits {
    color:var(--ynt-primary);
    font-weight:500;
}
/*pricing page ends*/